import React from 'react'
import './style.css';

export default function Logotag() {
  return (
    <div className='logotaghidden' >
         <div className="navbar ">
      <img src="logofullname.png" alt="Logo" className="logo" />
    </div>
    </div>
    


  )
}
