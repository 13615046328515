import React from 'react'
import './bridge.css';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function Bridge() {
  return (
    <div>
<section>
  <div  style={{display:'flex' , width:'100%' , height:'auto' , overflow:'hidden' , justifyContent:"space-between" , paddingLeft:'30px',paddingTop:'25px' , paddingRight:'20px' ,paddingBottom:'60px' }}>
  <img
              src='logohalfname.png'// Replace with the actual path to your logo image
              alt="Logo"
              style={{  height: '80px', width: 'auto',  }}
            />
                

            <div   >
              <div className='bridge-nav-collect-btn'  >
                Connect Wallet
              </div>
            </div>
  </div>
</section>



<div style={{display:'flex' , flexDirection:"column" , flexWrap:'wrap' , alignContent:'center' }} >


  <div  style={{display:'flex' , flexDirection:'row' , flexWrap:'nowrap' }}>

 
  <div>

  <select  className='m-2 h-20 custom-btn-1 fs-5 ' aria-label='dropdown' >
    {Array.from(Array(2), (e, i) => {
      return (
        <option>XRP Ledger</option>
      )
    })}
  </select>
  </div>
  <div>
  
  <select  className='m-2 h-20 custom-btn-1 fs-5 ' aria-label='dropdown' >
    {Array.from(Array(2), (e, i) => {
      return (
        <option>XRP Ledger</option>
      )
    })}
  </select>


  </div>
  </div>













  <div style={{  height:'60px'  ,display:'flex' , justifyContent:'space-around'}} >
   
  
    <input  style={{ width:'95%', height:'70px' ,borderRadius:'10px' ,display:'flex' , alignContent:'center'}} type="text" class="outlined-input" placeholder="Enter text..."/>


    </div>





</div>
      








    </div>
  )
}
