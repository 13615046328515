import React from "react";
// import "./footer.css";
import "./footernew.css";
import { div } from "react-router-dom";

const Footer = () => {
  return (
<div>







<footer class="footer">
  	 <div class="container">
  	 	<div class="row">


  	 		<div class="footer-col">
  	 			<h4>Need Help?</h4>

  	 			<div className="footerinnerboxes"  >   
{/* ul */}
					<div className="footerineritemssyt" style={{display:'flex' , flexDirection:'row'}}  >
						<div>  Visit  {" "}  
						
						<span> {" "}  our support centre </span>
						for troubleshooting
						
						  </div>
					   
						
					  </div>

					  <div className="footerineritemssyt" >
						 Join {" "} 
						  <div href="https://support.traderjoexyz.com/en/">
						 <span>  our offical Discord </span>
						  </div>{"  "}
						  
						   for dedicated support
						  
						</div>

						<div className="footerineritemssyt" >
							<div className="icon-row mx-3">
							  <span className="icon small-icon">
								<img
								  src="./discord.png"
								  alt=""
								  style={{ width: "40px", height: "40px" }}
								/>
							  </span>
							  <span className="icon small-icon">
								<img
								  src="./twt.png"
								  alt=""
								  style={{ width: "40px", height: "40px" }}
								/>
							  </span>
							  <span className="icon small-icon">
								<img
								  src="./bookmark.png"
								  alt=""
								  style={{ width: "40px", height: "40px" }}
								/>
							  </span>
							  <span className="icon small-icon">
								<img
								  src="./ss.png"
								  alt=""
								  style={{ width: "40px", height: "40px" }}
								/>
							  </span>
							  <span className="icon small-icon">
								<img
								  src="./github-sign.png"
								  alt=""
								  style={{ width: "37px", height: "37px" }}
								/>
							  </span>
							</div>
						  </div>

  	 	 {/* <div ><div href="#">about us</div></div >
  	 				<div ><div href="#">our services</div></div >
  	 				<div ><div href="#">privacy policy</div></div >
  	 				<div ><div href="#">affiliate program</div></div >  */}
  	 		
        	</div>



  	 		</div>


  	 		<div class="footer-col">
  	 			<h4>JOE Token </h4>
  	 			<div className="footerinnerboxes">


					<div className="footerineritemssyt" >
						<div href="#">Coingecko</div>
					  </div>
					  <div className="footerineritemssyt" >
						<div href="#">CoinMarketCap</div>
					  </div>
					  <div className="footerineritemssyt" >
						<br></br>{" "}
					  </div>

					  <div className="footerineritemssyt" >
  
            <h4>Analysis</h4>
					  <br/>
					  </div>
					  <div className="footerineritemssyt" >
						<div href="#">TokenTerminal</div>
					  </div>
					  <div className="footerineritemssyt" >
						<div href="#">Defi Llama</div>
					  </div>
					  <div className="footerineritemssyt" >
						<div href="#">Gecko Terminal</div>
					  </div>





  	 			</div>


  	 		</div>


  	 		 <div class="footer-col">
  	 			<h4>Exchanges</h4>
  	 			<div className="footerinnerboxes">


					<div className="footerineritemssyt"  >
						<div href="#">Binance</div>
					  </div >
					  <div className="footerineritemssyt" >
						<div href="#">Crypto.com</div>
					  </div >
					  <div className="footerineritemssyt" >
						<div href="#">Gate.io</div>
					  </div >
					  <div className="footerineritemssyt" >
						<div href="#">MEXC</div>
					  </div >
					  <div className="footerineritemssyt">
						<div href="#">OKX</div>
					  </div > 

  	 			</div>
  	 		</div>


  	 		 <div class="footer-col">
  	 			<h4> Buisness</h4>
				<div  className="footerinnerboxes">

					

						<div className="footerineritemssyt" >
							<div href="#">Apply for Patnership</div>
						  </div >
						  <div className="footerineritemssyt" >
							<div href="#">Token Listing</div>
						  </div >
						  <div className="footerineritemssyt">
							<div href="#">NFT Listing</div>
						  </div >
						  <div className="footerineritemssyt" >
							<div href="#">Contact Us</div>
						  </div >

				</div>


  	 		</div> 


  	 	</div>
  	 </div>
  </footer>


</div>

    
  );
};

export default Footer;
